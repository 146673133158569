<template>
    <div class="contained">

        <!-- The Confident One -->
        <div style="width: 550px; margin: auto;" v-if="maxVariable === 'Michael'" >
            <div class="content">
                <img src="../../assets/michael.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">Michael Scott</h1>
                <p class="is-size-5 has-text-white">As a recruiter, you're the life of the party. You love to make people laugh and feel comfortable, even if it means being a little goofy at times. You're not afraid to think outside the box and take risks to find the perfect candidate. You believe in creating an enjoyable environment for everyone.</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <!-- <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the confident recruiter! 😎</p> -->
            </div>
        </div>
        <!-- The Confident One Ends -->

         <!-- The Workaholic One -->
        <div style="width: 550px; margin: auto;"  v-if="maxVariable === 'Pam'">
            <div class="content">
                <img src="../../assets/pam.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">Pam Beesly</h1>
                <p class="is-size-5 has-text-white">You're the empathetic and understanding recruiter. You listen to the needs and concerns of your candidates and make sure they feel heard. Just like Pam, you're organized, detail-oriented, and always willing to help others. You might not always be the most assertive, but you're dependable and always get the job done.
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <!-- <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the workaholic recruiter! 😎</p> -->
            </div>
        </div>
        <!-- The Workaholic One Ends -->

        <!-- The Marketing Genius -->
        <div style="width: 550px; margin: auto;" v-if="maxVariable === 'Dwight'">
            <div class="content">
                <img src="../../assets/dwight.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">Dwight Schrute</h1>
                <p class="is-size-5 has-text-white">You're extremely dedicated and hardworking. You're not afraid to go the extra mile to find the perfect candidate. Like Dwight, you have a unique approach to recruiting and aren't afraid to be a little unconventional. You're competitive and always strive to be the best.
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <!-- <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the marketing genius! 😎</p> -->
            </div>
        </div>
        <!-- The Marketung Genius Ends -->

          <!-- The Research Hero -->
        <div style="width: 550px; margin: auto;" class="" v-if="maxVariable === 'Jim'">
            <div class="content">
                <img src="../../assets/jim.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">Jim Halpert</h1>
                <p class="is-size-5 has-text-white">You're the cool and laid-back recruiter. You have a knack for making people feel at ease and you're great at building relationships. You're not overly aggressive in your recruiting tactics, preferring to let things unfold naturally. You have a great sense of humor and know how to lighten the mood.
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <!-- <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the research hero! 😎</p> -->
            </div>
        </div>
        <!-- The Research Hero Ends -->


          <!-- The  The Extroverted One
 -->
        <div style="width: 550px; margin: auto;" class="" v-if="maxVariable === 'Andy'">
            <div class="content">
                <img src="../../assets/andy.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0"> Andy Bernard

</h1>
                <p class="is-size-5 has-text-white">As a recruiter, you're enthusiastic and passionate. You're not afraid to show your excitement and you're always eager to meet new people. You might be a little over the top at times, but your energy and dedication are infectious. You're always willing to go the extra mile to find the perfect candidate.


</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the extroverted recruiter! 🤝</p>
            </div>
        </div>
        <!-- The The Extroverted One
 Ends -->

           <!-- The  The Extroverted One
 -->
        <div style="width: 550px; margin: auto;" class="" v-if="maxVariable === 'Ryan'">
            <div class="content">
                <img src="../../assets/ryan.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">Ryan Howard

</h1>
                <p class="is-size-5 has-text-white">You're the ambitious and forward-thinking recruiter. You're always looking for new and innovative ways to attract candidates. You're not afraid to challenge the status quo and always looking to improve and grow. You're driven and determined to succeed.
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <!-- <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the extroverted recruiter! 🤝</p> -->
            </div>
        </div>
        <!-- The The Extroverted One
 Ends -->


        
    </div>
    
</template>

<script>
import { usePQOneStore } from '../../store/PQOne';
    export default {
        data() {
            return {
                name: "",
                email: "",
                agreement: false,
                displayAgreementWarning: false,
                displayInputWarning :false
            }
        },
        methods: {
            
        },
        computed: {
            maxVariable() {
            const store = usePQOneStore(); // Initialize the store
            const variables = Object.keys(store.$state);
            const values = variables.map(variable => store[variable]);

            const maxValue = Math.max(...values);
            const maxIndices = [];

            for (let i = 0; i < values.length; i++) {
                if (values[i] === maxValue) {
                maxIndices.push(i);
                }
            }

            if (maxIndices.length === 1) {
                return variables[maxIndices[0]];
            } else {
                const randomIndex = maxIndices[Math.floor(Math.random() * maxIndices.length)];
                return variables[randomIndex];
            }
            },
  },
}

</script>

<style scoped>

.contained {
    background-color: #092948;
    height: 100vh;
}

</style>