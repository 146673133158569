import { defineStore } from "pinia";

export const usePQTwoStore = defineStore("PQOneStore", {
  state: () => ({
    Michael: 0,
    Jim: 0,
    Pam: 0,
    Dwight: 0,
    Andy: 0,
    Ryan: 0
  }),
});
