<template>
  <h1 class="title mt-5 rcrm-blue has-text-weight-bold text-3xl the-section">What is the hiring goal you wish to achieve?</h1>

  <div class="container">
    <div class="is-flex is-flex-direction-column text-left" style="align-items: flex-start;">
      <label class="checkbox mt-3 text-xl">
        <input type="checkbox" v-model="checkboxStore.candidateSourcing">
        Become a pro at candidate sourcing
      </label>

      <label class="checkbox mt-3 text-xl">
        <input type="checkbox" v-model="checkboxStore.candidateExperience">
        Improve candidate experience
      </label>

      <label class="checkbox mt-3 text-xl">
        <input type="checkbox" v-model="checkboxStore.recruitmentMarketing">
        Boost recruitment marketing efforts
      </label>

      <label class="checkbox mt-3 text-xl">
        <input type="checkbox" v-model="checkboxStore.recruitmentAutomation">
        Streamline and automate the recruitment process
      </label>

      <label class="checkbox mt-3 text-xl">
        <input type="checkbox" v-model="checkboxStore.diversityHiring">
        Focus on diversity hiring
      </label>
    </div>
    <button class="rcrm-green-button mt-5" @click="submitResponse" style="padding: 20px !important; font-size: 18px;">Access the free guides now
</button>
  </div>
</template>


<script>
import { useCheckboxStore } from '../../store/store.js'; 

export default {
  data() {
    return {
      checkboxStore: useCheckboxStore(),
    }
  },
  mounted() {
     this.checkboxStore.selectedCheckboxes.candidateExperience = false;
            this.checkboxStore.selectedCheckboxes.candidateSourcing = false;
            this.checkboxStore.selectedCheckboxes.recruitmentMarketing = false;
            this.checkboxStore.selectedCheckboxes.recruitmentAutomation = false;
            this.checkboxStore.selectedCheckboxes.diversityHiring = false;
  },
  methods: {
    submitResponse() {
      // You can perform any additional processing here before storing the data
      // For simplicity, we are just printing the selected values
      console.log('Selected checkboxes:', this.checkboxStore.selectedCheckboxes);
      this.$router.push('/turp-two');
    },
  },
}
</script>



<style scoped>


.container {
    width: 500px;
}

@media only screen and (max-width: 481px) {
  
  .the-section {
    padding: 20px;
  }

  .container {
    padding: 20px;
    width: 450px;
  }
  
}
</style>