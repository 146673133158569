// store.js
import { defineStore } from "pinia";

export const useCheckboxStore = defineStore("checkbox", {
  state: () => ({
    candidateSourcing: false,
    candidateExperience: false,
    recruitmentMarketing: false,
    recruitmentAutomation: false,
    diversityHiring: false,
  }),
  getters: {
    selectedCheckboxes() {
      return {
        candidateSourcing: this.candidateSourcing,
        candidateExperience: this.candidateExperience,
        recruitmentMarketing: this.recruitmentMarketing,
        recruitmentAutomation: this.recruitmentAutomation,
        diversityHiring: this.diversityHiring,
      };
    },
  },
});
