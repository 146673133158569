<template>
     <div class="Section">
  <div class="parent">
  <h1 class="title mt-1 rcrm-blue has-text-weight-bold mt-10">Diversity hiring 101</h1>
  </div>

  <div class="container">
    <p>With our comprehensive guide on diversity hiring, get equipped with strategies to attract, hire, and retain a diverse workforce, enriching your organization's culture and creativity.</p>
    <!-- <p class="mt-5">We have a bonus guide for you that includes a ready-to-use inclusive job descriptions. </p> -->
  </div>

  <div class="card-container is-flex is-flex-direction-row text-center">
    <div>
      <!-- card 1 -->
        <div class="card mr-4">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="https://recruitcrm.io/wp-content/uploads/2023/06/handsome-african-guy-with-happy-face-expression-supporting-his-friends-before-conference-indoor-portrait-work-team-young-international-specialists-preparing-meeting-with-chief-min-768x512.jpg" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                  <p style="font-weight:600; font-size:15px; margin-top: 0px;">All about diversity hiring</p>
                  <a href="https://recruitcrm.io/wp-content/uploads/2023/08/All-about-diversity-hiring-2.pdf" target="_blank">
                  <button class="button mt-0 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
    <div>
      <!-- card 2 -->
         <div class="card">
          <div class="card-image">
            <figure class="image is-4by3 relative">
              <img src="https://recruitcrm.io/wp-content/uploads/2023/04/pexels-thirdman-5256819-1-768x512.jpg" alt="Placeholder image">
               <span class="absolute inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-bold text-green-700 top-2 left-2">Bonus Guide</span>
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                   <p class="" style="font-weight:600; font-size:15px">Job description templates</p>
                   <a href="https://recruitcrm.io/wp-content/uploads/2023/08/Diversity-hiring-bonus-guide-2.pdf" target="_blank">
                  <button class="button mt-1 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
  </div>

  <div class="divider">
    <hr>
  </div>
  </div>
</template>

<style scoped>

h1 {
  font-weight: 900 !important;
}

p {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.container {
    width: 70%;
    margin-top: 25px;
}

.card-container {
    width: 28%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 481px) {
  
  .pateny {
    padding: 10px;
  }
  .card-container {
    width: 100%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}
}

</style>