<template>
    <div>
      <ROICalculator />  
    </div>
</template>

<script>
import ROICalculator from '@/components/ROI/ROICalculator.vue';
export default {
    name: "ROICalculatorView",
    components: {
        ROICalculator
    }    
}
</script>