<template>
  <candidateExperience v-if="checkboxStore.selectedCheckboxes.candidateExperience"/>
  <CandidateSourcing v-if="checkboxStore.selectedCheckboxes.candidateSourcing"/>
 <RecruitmentMarketing v-if="checkboxStore.selectedCheckboxes.recruitmentMarketing"/>
  <RecruitmentProcess v-if="checkboxStore.selectedCheckboxes.recruitmentAutomation" />
  <DiversityHiring v-if="checkboxStore.selectedCheckboxes.diversityHiring"/>
  <WarningNote v-if="!checkboxStore.selectedCheckboxes.candidateExperience && !checkboxStore.selectedCheckboxes.candidateSourcing && !checkboxStore.selectedCheckboxes.recruitmentMarketing && !checkboxStore.selectedCheckboxes.recruitmentAutomation && !checkboxStore.selectedCheckboxes.diversityHiring"></WarningNote>
   <div> <button @click="goBack()" class="button p-5" style="color: white; font-weight: 600; background-color: #106fca"> Take the quiz again </button></div>
</template>


<script>
import { useCheckboxStore } from '../../store/store.js'; 
import candidateExperience from '../../components/TURP/CandidateExperience.vue'
import CandidateSourcing from '../../components/TURP/CandidateSourcing.vue'
import RecruitmentMarketing from '../../components/TURP/RecruitmentMarketing.vue'
import RecruitmentProcess from '../../components/TURP/RecruitmentProcess.vue'
import DiversityHiring from '../../components/TURP/DiversityHiring.vue'
import WarningNote from '../../components/TURP/WarningNote.vue'

export default {
  components: {
    candidateExperience,
    RecruitmentMarketing,
    RecruitmentProcess,
    DiversityHiring,
    CandidateSourcing,
    WarningNote
  },
  data() {
    return {
      checkboxStore: useCheckboxStore(),
    }
  },
  methods: {
    goBack() {
            window.location.href = 'https://mediarcrm.com'
        },
    submitResponse() {
      // You can perform any additional processing here before storing the data
      // For simplicity, we are just printing the selected values
      console.log('Selected checkboxes:', this.checkboxStore.selectedCheckboxes);
    },
  },
}
</script>



