<template>

  <div class="container">
    <form class="custom-form" @submit.prevent="addToMailchimp()">
        <p class="mb-4" :class="{ hidden : dangerIsHidden}" style="color: red">Please enter valid details to proceed.</p>
        <p class="mb-4" :class="{ hidden : successIsHidden}" style="color: green"> Submitted Successfully. Redirecting...</p>
      <div class="field mb-5">
        <label class="label">1. What's your full name?</label>
        <div class="control">
          <input class="input" v-model="name" type="text"  placeholder="John Doe" required>
        </div>
      </div>

      <div class="field mb-5">
        <label class="label">2. Your business/work email ID</label>
        <div class="control">
          <input class="input" v-model="email" type="email" placeholder="johndoe@example.com" required>
        </div>
        <p style="color:red" :class="{hidden: emailWarningHidden}">This email Id is already registered.</p>
      </div>

      <div class="field mb-5">
        <label class="label">3. What's your current LinkedIn follower count?</label>
        <div class="control">
          <input class="input" v-model="linkedinFollowers" type="number"  placeholder="10000" required>
        </div>
      </div>

      <div class="field mb-5">
        <label class="label">4. Please add the link to your LinkedIn profile below.</label>
        <div class="control">
          <input class="input" v-model="linkedinProfile" type="text"  placeholder="https://www.linkedin.com/in/johndoe" required>
        </div>
      </div>

      <div class="field mb-5">
        <div class="control">
          <button class="button is-primary" type="submit" style="background-color: #106fca;">Start your journey</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      fullName: '',
      email: '',
      followerCount: '',
      linkedinProfile: '',
      linkedinFollowers: '',
      tags: '',
      dangerIsHidden: true,
      successIsHidden: true,
      emailWarningHidden: true
    };
  },
  methods: {

     async addToMailchimp() {

            if(this.linkedinFollowers >= 5000) {
                this.tags = 'Qualified'
            }
            else {
                this.tags = 'Disqualified'
            }
            const formData = {
            email: this.email,
            name: this.name,
            linkedinFollowers: this.linkedinFollowers,
            linkedinProfile: this.linkedinProfile,
            listId: 'cf69c16338',
            tags: this.tags
        };

        if(this.isValidEmail(this.email)) {
        axios
        .post('https://spartans.mediarcrm.com/api/influencer-subscribe', formData) // Replace with your actual API endpoint
        .then(response => {
            this.successIsHidden = false;
             // Create an anchor element
            const anchor = document.createElement('a');
            anchor.href = " https://recruitcrm.io/thank-you-for-applying/";
            anchor.target = "_blank";

            // Programmatically trigger a click event on the anchor element
            anchor.click();
            setTimeout(() => {
                this.successIsHidden = true;
            }, 5000);
            this.name= '',  
            this.email = '',
            this.linkedinProfile = '',
            this.linkedinFollowers = '',

          console.log(response.data.message);
        })
        .catch(error => {
            this.dangerIsHidden = false;
            setTimeout(() => {
                this.dangerIsHidden = true;
            }, 5000);
          console.log(error);
          if(error.response.data.message.includes('Member Exists')) {
            this.emailWarningHidden = false;
            setTimeout(() => {
                this.emailWarningHidden = true
            }, 5000);
          }
        }); }
      },
    submitForm() {
      // You can perform form submission or validation here
      // For demonstration purposes, let's log the form data
      console.log({
        fullName: this.fullName,
        email: this.email,
        followerCount: this.followerCount,
        linkedinProfile: this.linkedinProfile
      });
    },
     isValidEmail(email) {
            // Regular expression to validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.input {
  text-align: left;
}

label {
  text-align: left;
}
</style>
