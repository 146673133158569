<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-base font-semibold text-indigo-600 rcrm-blue text-xl ">Oops!</p>
      <h1 class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl" style="font-family: sans-serif;">Looks like you forgot to select an option</h1>
      <p class="mt-2 text-xl text-base leading-7 text-gray-600" style="font-family: sans-serif;">Select at least one option to access our variety of guides to help you become an ace recruiter</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <!-- <a href="#" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a> -->
      </div>
    </div>
  </main>
</template>

<script>

export default {
    methods: {
        goBack() {
            this.$router.push('/');
        }
    }
}

</script>

<style scoped>
* {
  font-family: 'Roboto', sans-serif !important;
}
</style>