import { createRouter, createWebHistory } from 'vue-router'
import InfluencerEntrollment from "../views/Influencers/InfluencerEnrollment.vue";
import PageOne from '../views/TURP/PageOne.vue';
import PageTwo from "../views/TURP/PageTwo.vue";
import ROICalculator from "../views/ROI/ROICalculator.vue";
import RoiResult from "@/components/ROI/RoiResult.vue"

import Quiz from "../views/PQ/PQHome.vue";
import PQOne from "../components/PQ/PQOne.vue";
import PQTwo from "../components/PQ/PQTwo.vue";
import PQThree from "../components/PQ/PQThree.vue";
import PQFour from "../components/PQ/PQFour.vue";
import PQFive from "../components/PQ/PQFive.vue";
import PQSix from "../components/PQ/PQSix.vue";
import PQSeven from "../components/PQ/PQSeven.vue";
import PQEight from "../components/PQ/PQEight.vue";
import PQNine from "../components/PQ/PQNine.vue";
import PQTen from "../components/PQ/PQTen.vue";
import PQEleven from "../components/PQ/PQEleven.vue";
import PQTwelve from "../components/PQ/PQTwelve.vue";
import PQResult from "../views/PQ/PQResult.vue"

import PQ2One from "../components/PQTwo/PQOne.vue";
import PQ2Two from "../components/PQTwo/PQTwo.vue";
import PQ2Three from "../components/PQTwo/PQThree.vue";
import PQ2Four from "../components/PQTwo/PQFour.vue";
import PQ2Five from "../components/PQTwo/PQFive.vue";
import PQ2Six from "../components/PQTwo/PQSix.vue";
import PQ2Seven from "../components/PQTwo/PQSeven.vue";
import PQ2Eight from "../components/PQTwo/PQEight.vue";
import PQ2Nine from "../components/PQTwo/PQNine.vue";
import PQ2Ten from "../components/PQTwo/PQTen.vue";
import PQ2Result from "../views/PQTwo/PQResult.vue";
import PersonalityQuiz2 from "../views/PQTwo/PQHome.vue";

import JDGenerator from "../views/JobDescription/JDGenerator"
const routes = [
  {
    path: "/",
    name: "PageOne",
    component: PageOne,
  },
  {
    path: "/turp-two",
    name: "PageTwo",
    component: PageTwo,
  },
  {
    path: "/roi-calculator",
    name: "ROICalculator",
    component: ROICalculator,
  },
  {
    path: "/roi-result",
    name: "roiResult",
    component: RoiResult,
  },
  {
    path: "/personality-quiz",
    name: "PersonalityQuiz",
    component: Quiz,
  },

  // PQ Routes
  {
    path: "/pq-one",
    name: "PQOne",
    component: PQOne,
  },
  {
    path: "/pq-two",
    name: "PQTwo",
    component: PQTwo,
  },
  {
    path: "/pq-three",
    name: "PQThree",
    component: PQThree,
  },
  {
    path: "/pq-four",
    name: "PQFour",
    component: PQFour,
  },
  {
    path: "/pq-five",
    name: "PQFive",
    component: PQFive,
  },
  {
    path: "/pq-six",
    name: "PQSix",
    component: PQSix,
  },
  {
    path: "/pq-seven",
    name: "PQSeven",
    component: PQSeven,
  },
  {
    path: "/pq-eight",
    name: "PQEight",
    component: PQEight,
  },
  {
    path: "/pq-nine",
    name: "PQNine",
    component: PQNine,
  },
  {
    path: "/pq-ten",
    name: "PQTen",
    component: PQTen,
  },
  {
    path: "/pq-eleven",
    name: "PQEleven",
    component: PQEleven,
  },
  {
    path: "/pq-twelve",
    name: "PQTwelve",
    component: PQTwelve,
  },
  {
    path: "/pq-one-result",
    name: "PQResult",
    component: PQResult,
  },

  // PQ 2 Routes

  {
    path: "/personality-quiz-2",
    // name: "PersonalityQuiz",
    component: PersonalityQuiz2,
  },

  {
    path: "/pq2-one",
    // name: "PQOne",
    component: PQ2One,
  },
  {
    path: "/pq2-two",
    // name: "PQTwo",
    component: PQ2Two,
  },
  {
    path: "/pq2-three",
    // name: "PQThree",
    component: PQ2Three,
  },
  {
    path: "/pq2-four",
    // name: "PQFour",
    component: PQ2Four,
  },
  {
    path: "/pq2-five",
    // name: "PQFive",
    component: PQ2Five,
  },
  {
    path: "/pq2-six",
    // name: "PQSix",
    component: PQ2Six,
  },
  {
    path: "/pq2-seven",
    // name: "PQSeven",
    component: PQ2Seven,
  },
  {
    path: "/pq2-eight",
    // name: "PQEight",
    component: PQ2Eight,
  },
  {
    path: "/pq2-nine",
    // name: "PQNine",
    component: PQ2Nine,
  },
  {
    path: "/pq2-ten",
    // name: "PQTen",
    component: PQ2Ten,
  },
  {
    path: "/pq2-result",
    // name: "PQResult",
    component: PQ2Result,
  },

  // Influecner Enrollment
  {
    path: "/influencer-enrollment",
    name: "InfluencerEnrollment",
    component: InfluencerEntrollment,
  },

  {
    path: "/jd-generator",
    name: "JDGenerator",
    component: JDGenerator,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
