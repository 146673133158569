<template>
    <div class="contained">
        <div class="content">
            <h1 class="is-size-1 has-text-white" style="padding-top: 10%">How much time do you spend sourcing <br> candidates from social media?


</h1>
            <!-- <p class="is-size-5 has-text-white">Ever thought of what your true recruiter personality is? Take <br> this quiz and find out.</p> -->
        </div>

        <div>
             <!-- <p class="is-size-5 has-text-white has-text-weight-bold">Fill in the details to get started</p> -->
             <div style="width:25%; margin: auto">
                <div>
                    <label class="checkbox" @click="optionOne()" :class="{selected: options.yes}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-4 ml-4">4+ hours</span>
                    </label>
                </div>

                <div class="mt-5">
                    <label class="checkbox" @click="optionThree()" :class="{selected: options.maybe}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-4 ml-4">1-2 hours</span>
                    </label>
                </div>

                 <div class="mt-5">
                    <label class="checkbox" @click="optionTwo()" :class="{selected: options.no}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-4 ml-4">Not a fan of social recruiting</span>
                    </label>
                </div>

                <button class="mt-5 is-large button is-warning is-outlined" style="margin-left:25px"  @click="submit()">Next</button>

             </div>
             
        </div>
            <div class="buttons has-addons absolute bottom-0 right-0">
             <button class="button is-selected border-0" @click="PreviousPage()">Previous</button>
            <button class="button" @click="NextPage()">Next</button>
        </div>
    </div>
</template>

<script>
import { usePQOneStore } from '../../store/PQOne';
    export default {
        data() {
            return {
                options: {
                    yes: null, 
                    no: null,
                    maybe: null
                },
                store: usePQOneStore()
            }
        }, 
          methods: {
            optionOne() {
                this.options.yes= true;
                this.options.no = false;
                this.options.maybe = false;
            },

             optionTwo() {
                this.options.yes= false;
                this.options.no = true;
                this.options.maybe = false;
            },
             optionThree() {
                this.options.yes= false;
                this.options.no = false;
                this.options.maybe = true;
            },
            submit() {
                if(this.options.yes) {
                    this.store.MarketingGenius = this.store.MarketingGenius + 4;
                }

                else if (this.options.no) {
                    this.store.MarketingGenius = this.store.MarketingGenius + 0;
                }
                else if (this.options.maybe) {
                    this.store.MarketingGenius = this.store.MarketingGenius + 2;
                }

                this.$router.push('/pq-ten')
            },
            NextPage() {
                this.$router.push('/pq-ten')
            },
            PreviousPage() {
                this.$router.push('/pq-eight')
            }
        }
    }

</script>

<style scoped>

.contained {
    background-color: #092948;
    height: 100vh;
}

.checkbox {
  display: inline-block;
  padding-left: 0px !important; /* Add padding to accommodate larger custom checkbox */
  position: relative;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2); /* Transparent white background */
  border: 1px solid white; /* Solid white border */
  padding: 15px;
  border-radius: 4px;
  color: white;
  width:400px;
  padding-right: 20px;
}

.checkbox input[type="checkbox"] {
  opacity: 0; /* Hide the actual checkbox */
}


.checkbox input[type="checkbox"]:checked + ::before {
  background-color: white;
}

.selected {
    border: 2px solid #ffe089;
}

</style>