<template>
  <div class="mx-auto flex">
    <div class="w-3/5 p-20 pt-10" style="background-color: #2376cd">
      <!-- <h1 class="text-3xl font-bold mb-4">ROI Calculator</h1> -->

      <div v-if="!showUserInfoForm">
        <form @submit.prevent="submitOtherInformation" class="-ml-14 p-10 bg-white  text-left"
          style="background-color: #2376cd">
          <h1 class="headingg title">How much time & money
            would you save by using an
            applicant tracking system?</h1>

          <div class="mb-5">
            <label for="dailyEmails" class="block mb-2 text-white">Number of emails you send every day</label>
            <input placeholder="0" type="number" min="1" max="1000" id="dailyEmails" v-model="dailyEmails" required
              class="w-full px-4 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="timePerEmail" class="block mb-2">Time taken to submit each email (in minutes)</label>
            <input placeholder="0" type="number" min="1" max="1000" id="timePerEmail" v-model="timePerEmail" required
              class="w-full px-3 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="timeForContext" class="block mb-2">Time spent daily gaining context (in minutes)</label>
            <input placeholder="0" type="number" min="1" max="1000" id="timeForContext" v-model="timeForContext"
              required class="w-full px-3 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="timeForSourcing" class="block mb-2">Time spent daily sourcing candidates (in minutes)</label>
            <input placeholder="0" type="number" min="1" max="1000" id="timeForSourcing" v-model="timeForSourcing"
              required class="w-full px-4 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="timeForReview" class="block mb-2">Time spent daily reviewing resumes (in minutes)</label>
            <input placeholder="0" type="number" min="1" max="1000" id="timeForReview" v-model="timeForReview" required
              class="w-full px-3 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="timeForInterviews" class="block mb-2">Time taken to set up interviews daily (in minutes)</label>
            <input placeholder="0" type="number" min="1" max="1000" id="timeForInterviews" v-model="timeForInterviews"
              required class="w-full px-4 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <div class="mb-5">
            <label for="hourlyRate" class="block mb-2">Hourly rate in US dollars</label>
            <input placeholder="0" type="number" min="1" max="1000" id="hourlyRate" v-model="hourlyRate" required
              class="w-full px-3 py-4 border rounded text-xl has-text-weight-bold" />
            <p class="text-white">The value needs to be greater than 0.</p>
          </div>

          <button type="submit" class="w-full is-size-4 py-4 bg-white text-white py-2 px-4 rounded"
            style="background-color: #10ac7b; font-weight: 600;">Calculate ROI</button>
        </form>
      </div>

      <div v-if="showUserInfoForm">

        <form v-if="showInfoBox" @submit.prevent="submitUserInformation"
          style="background-color: #2376cd; height: 100vh" class="mt-4 max-w-lg mx-auto p-4 bg-white rounded text-left">
          <h1 class="title" style="color: white; font-size: 28px; margin-bottom: 20px;">Fill in your details to get the
            answer :)</h1>
          <div class="mb-4">
            <label for="name" class="block mb-5">Name:</label>
            <input placeholder="Name" type="text" id="name" v-model="name" required
              class="w-full px-4 py-4 border rounded text-lg has-text-weight-semi-bold" />
          </div>

          <div class="mb-4">
            <label for="email" class="block mb-5">Email:</label>
            <input placeholder="Email" type="email" id="email" v-model="email" required
              class="w-full px-4 py-4 border rounded text-lg has-text-weight-semi-bold" />
          </div>

          <div class="mb-4">
            <label for="company" class="block mb-5">Company:</label>
            <input placeholder="Company" type="text" id="company" v-model="company" required
              class="w-full px-4 py-4 border rounded text-lg has-text-weight-semi-bold" />
          </div>

          <div class="mb-4">
            <label for="recruitingSoftware" class="block mb-5">Which recruiting software are you currently
              using?</label>
            <select id="recruitingSoftware" v-model="current_ATS" required
              class="w-full px-4 py-4 border rounded text-lg has-text-weight-semi-bold">
              <option value="" class="text-black" disabled selected>Select an option</option>
              <option value="Recruit CRM">Recruit CRM</option>
              <option value="Bullhorn">Bullhorn</option>
              <option value="Vincere">Vincere</option>
              <option value="Firefish">Firefish</option>
              <option value="Loxo">Loxo</option>
              <option value="Mercury">Mercury</option>
              <option value="Recruitee">Recruitee</option>
              <option value="Recruiterflow">Recruiterflow</option>
              <option value="Ceipal">Ceipal</option>
              <option value="Zoho Recruit">Zoho Recruit</option>
              <option value="Crelate">Crelate</option>
              <option value="Not using any">Not using any</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <button type="submit" class="button submitButton is-size-4"
            style="background-color: #10ac7b; font-weight: 600;">See the results</button>
        </form>

      </div>

      <!-- <div class="text-white py-10 px-5 text-left" id="calendly-container" style="background-color: #2475cd; height: 100vh;" v-if="showResult" > 
        <div >
      <h2 class="text-2xl leading-10 font-bold mb-5 is-size-2 mb-2 mt-2" style="font-family: sans-serif;">Hey {{ name }}, <br> here are your results</h2>

      <p class="text-xl text-white mb-4 mt-20 is-size-3 font-medium leading-10">You're saving <strong class="text-white font-medium">{{hourSaved}} hours </strong>every month if you're using an ATS
</p>
      <p class="text-xl text-white mb-4 mt-10 is-size-3 font-medium leading-10">Your ROI is $ <strong class="text-white font-medium">{{ roi }}</strong> every month if you're investing in an ATS</p>
       <button type="submit" class="w-full py-4 bg-white text-white py-2 px-4 rounded is-size-4 mt-10" style="background-color: #10ac7b; font-weight: 600;" @click="newCalculation()">Start New Calculation</button>
      <h3 class="text-white is-size-4 mt-4 has-text-weight-bold" style="font-family:sans-serif">Book a demo to find out how much you can save</h3>
      
          <div class="calendly-inline-widget" data-url="https://calendly.com/recruitcrm/demo" style="min-width:320px;height:700px;" />

    </div>
    </div> -->
      <!-- Calendly -->

      <!-- Calendly Ends -->
    </div>

    <!-- Second Half of the Screen -->
    <div class="w-2/5 pt-2 bg-blue-200">

    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { useCalcStore } from '@/store/calc';
export default {
  data() {
    return {
      name: '',
      email: '',
      company: '',
      showResult: false,
      showUserInfoForm: false,
      showInfoBox: false,
      roi: '',
      dailyEmails: '',
      timePerEmail: '',
      timeForContext: '',
      timeForSourcing: '',
      timeForReview: '',
      timeForInterviews: '',
      hourlyRate: '',
      hourSaved: '',
      current_ATS: '',
    };
  },
  methods: {
    // async addToMailchimp(email, firstName, lastName) {
    //   const apiUrl = 'https://us14.api.mailchimp.com/3.0/lists/529305521c/members';
    //   const apiKey = '4a794a41518df8c7e8da55b521b731d3-us14';

    //   try {
    //     // Data to be sent to Mailchimp
    //     const subscriberData = {
    //       email_address: this.email,
    //       status: 'subscribed', // or 'pending' if you want to send a double opt-in email
    //       merge_fields: {
    //         FNAME: this.firstName,
    //         COMPANY: this.company,
    //         EMAIL: this.email
    //       },
    //     };

    //     // Axios POST request to Mailchimp API
    //     const response = await axios.post(apiUrl, subscriberData, {
    //       headers: {
    //         'Authorization': `Basic ${btoa(`apikey:${apiKey}`)}`,
    //         'Content-Type': 'application/json',
    //       },
    //     });

    //     console.log('Mailchimp response:', response.data);
    //     // Handle the response as needed (e.g., show success message)
    //   } catch (error) {
    //     console.error('Error:', error.response ? error.response.data : error.message);
    //     // Handle errors appropriately (e.g., show error message)
    //   }
    // },

    async addToMailchimp() {
      const formData = {
        email: this.email,
        name: this.name,
        company: this.company,
        tags: 'ROI Calculator',
        current_ats: this.current_ATS,

      };
      axios
        .post('https://spartans.mediarcrm.com/api/subscribe', formData) // Replace with your actual API endpoint
        .then(response => {
          console.log(response.data.message);
        })
        .catch(error => {
          console.log('Subscription failed');
        });
    },
    async submitForm() {

      await this.addToMailchimp();
      // Replace with your actual API endpoint URL
      const apiUrl = 'https://recruitcrm.io/wp-json/gf/v2/entries';
      const clientKey = 'ck_9b123faeb95c8ca71be9d600b870c6fdedf2b3e2';
      const clientSecret = 'cs_43aa1d28030bf8195b5ec2bf21756ea31f26ae46';

      // JSON data for the POST request
      const formData = {
        "form_id": "10",
        "date_created": "2018-10-16 12:43:23",
        "is_starred": 0,
        "is_read": 1,
        "ip": "::1",
        "source_url": "https://recruitcrm.io/",
        "currency": "USD",
        "created_by": 1,
        "user_agent": "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:38.0) Gecko/20100101 Firefox/38.0",
        "status": "active",
        "1": this.name,
        "3": this.email,
        "4": this.company,
        "6": this.dailyEmails,
        "8": this.timePerEmail,
        "10": this.timeForContext,
        "9": this.timeForSourcing,
        "11": this.timeForReview,
        "12": this.timeForInterviews,
        "13": this.hourlyRate,
        "14": this.hourSaved,
        "15": this.roi,
        "17": this.current_ATS

      };



      try {
        // Encode client key and secret
        const encodedCredentials = btoa(`${clientKey}:${clientSecret}`);

        // Axios POST request
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json',
          },
        });

        console.log('Response:', response.data);
        // Handle the response as needed
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        // Handle errors appropriately
      }
    },
    submitOtherInformation() {
      this.showUserInfoForm = true;
      this.showInfoBox = true;
    },
    submitUserInformation() {
      if (this.name && this.email && this.company) {
        this.calculateROI();
        this.submitForm()
        useCalcStore().hours = this.hourSaved;
        useCalcStore().roi = this.roi;
        useCalcStore().name = this.name;
        // this.showResult = true;
        // this.showInfoBox = false;
        // this.generateCalendlyWidget();
        this.$router.push('/roi-result');
      }
    },
    newCalculation() {
      location.reload();
    },
    calculateROI() {

      this.roi = parseInt(((this.timePerEmail - 0.5) * this.dailyEmails + this.timeForContext * 0.7 + this.timeForSourcing * 0.25 + this.timeForReview * 0.3 + this.timeForInterviews * 0.25) / 60 * 22 * this.hourlyRate);

      this.hourSaved = parseInt(((this.timePerEmail - 0.5) * this.dailyEmails + this.timeForContext * 0.7 + this.timeForSourcing * 0.25 + this.timeForReview * 0.3 + this.timeForInterviews * 0.25) / 60 * 22);
    },
    loadCalendlyWidget() {
      // Create the Calendly badge widget script element
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;

      // Set the onload event to initialize the widget after the script is loaded
      script.onload = () => {
        Calendly.initBadgeWidget({
          url: 'https://calendly.com/recruitcrm/demo',
          text: 'I want a demo',
          color: '#0069ff',
          textColor: '#ffffff',
          branding: undefined
        });
      };

      // Append the script to the document's body
      document.body.appendChild(script);
    },
    generateCalendlyWidget() {
      // Create a new div element
      const calendlyDiv = document.createElement("div");
      calendlyDiv.className = "calendly-inline-widget";
      calendlyDiv.setAttribute("data-url", "https://calendly.com/recruitcrm/demo");
      calendlyDiv.style.minWidth = "320px";
      calendlyDiv.style.height = "700px";

      // Append the div to a container (you can customize the container as needed)
      const container = document.getElementById("calendly-container");
      if (container) {
        container.appendChild(calendlyDiv);
      }
    }
  },
  mounted() {
    this.loadCalendlyWidget()
  },

};
</script>

<style scoped>
.headingg {
  font-size: 28px;
  line-height: normal;
  color: white;
}

.submitButton {
  background-color: #10ac7b;
  font-weight: 600;
  color: white;
  border: none;
  padding: 25px;
  width: 100%;
  font-size: 22px;
}

.submitButton:hover {
  background-color: #10ac7b;
  font-weight: 600;
  color: white;
  border: none;
  padding: 25px !important;
  width: 100%;
}


.container {
  font-family: 'Roboto', sans-serif;
  width: 100wh;
}

.container>div {
  box-sizing: border-box;
}

/* form input[type="text"],
form input[type="email"],
form input[type="number"] {
  background-color: rgba(255, 255, 255, 0.8);
  color: #115590;
} */

form label {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
</style>