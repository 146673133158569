<template>
    <div class="contained">
        <div class="content">
            <h1 class="is-size-2 has-text-white">How do you approach learning and development <br> in your role?</h1>
            <!-- <p class="is-size-5 has-text-white">Ever thought of what your true recruiter personality is? Take <br> this quiz and find out.</p> -->
        </div>

        <div>
             <!-- <p class="is-size-5 has-text-white has-text-weight-bold">Fill in the details to get started</p> -->
             <div style="margin: auto">
                <div>
                    <label class="checkbox" @click="optionOne()" :class="{selected: options.Michael}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I learn by doing and not taking things too seriously</span>
                    </label>
                </div>

                <div class="mt-5">
                    <label class="checkbox" @click="optionTwo()" :class="{selected: options.Jim}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I learn from my experiences and interactions with others</span>
                    </label>
                </div>

                <div class="mt-5">
                    <label class="checkbox" @click="optionThree()" :class="{selected: options.Pam}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I value feedback and learn from my mistakes</span>
                    </label>
                </div>

                 <div class="mt-5">
                    <label class="checkbox" @click="optionFour()" :class="{selected: options.Dwight}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I'm always researching and seeking out new information</span>
                    </label>
                </div>

                 <div class="mt-5">
                    <label class="checkbox" @click="optionFive()" :class="{selected: options.Andy}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I learn by staying positive and open to new experiences </span>
                    </label>
                </div>
                
                 <div class="mt-5">
                    <label class="checkbox" @click="optionSix()" :class="{selected: options.Ryan}">
                    <input type="checkbox">
                    <span class="text-white ml-2 is-size-5 ml-4">I'm always looking for ways to improve and grow</span>
                    </label>
                </div>


                <button class="mt-5 is-large button is-warning is-outlined" @click="submit()">Next</button>

             </div>
             
        </div>
           <div class="buttons has-addons absolute right-0 bottom-0 ">
             <button class="button is-selected border-0" @click="PreviousPage()">Previous</button>
            <button class="button disabled" disabled>Next</button>
        </div>
    </div>
</template>

<script>
import {usePQTwoStore} from '../../store/PQTwo'
    export default {
        data() {
            return {
                options: {
                    Michael: null,
                    Jim: null,
                    Pam: null,
                    Dwight: null,
                    Andy: null,
                    Ryan: null
                    
                },
                store: usePQTwoStore()
            }
        }, 
        methods: {
            optionOne() {
                this.options.Michael= true;
                this.options.Jim = false;
                this.options.Pam = false;
                this.options.Dwight = false;
                this.options.Andy = false;
                this.options.Ryan = false;
            },

             optionTwo() {
                this.options.Michael= false;
                this.options.Jim = true;
                this.options.Pam = false;
                this.options.Dwight = false;
                this.options.Andy = false;
                this.options.Ryan = false;
            },
             optionThree() {
                this.options.Michael= false;
                this.options.Jim = false;
                this.options.Pam = true;
                this.options.Dwight = false;
                this.options.Andy = false;
                this.options.Ryan = false;
            },

            optionFour() {
                this.options.Michael= false;
                this.options.Jim = false;
                this.options.Pam = false;
                this.options.Dwight = true;
                this.options.Andy = false;
                this.options.Ryan = false;
            },
            optionFive() {
                this.options.Michael= false;
                this.options.Jim = false;
                this.options.Pam = false;
                this.options.Dwight = false;
                this.options.Andy = true;
                this.options.Ryan = false;
            },
            optionSix() {
                this.options.Michael= false;
                this.options.Jim = false;
                this.options.Pam = false;
                this.options.Dwight = false;
                this.options.Andy = false;
                this.options.Ryan = true;
            },
            submit() {
                if(this.options.Michael) {
                    this.store.Michael = this.store.Michael + 1;
                }

                else if (this.options.Jim) {
                    this.store.Jim = this.store.Jim + 1;
                }
                else if (this.options.Pam) {
                    this.store.Pam = this.store.Pam + 1;
                }

                 else if (this.options.Dwight) {
                    this.store.Dwight = this.store.Dwight + 1;
                }

                 else if (this.options.Andy) {
                    this.store.Andy = this.store.Andy + 1;
                }

                 else if (this.options.Ryan) {
                    this.store.Ryan = this.store.Ryan + 1;
                }


                this.$router.push('/pq2-result')
            },
            // NextPage() {
            //     this.$router.push('/pq2-result')
            // },
             PreviousPage() {
                this.$router.push('/pq2-nine')
            }
            
            
        }
        
    }

</script>

<style scoped>

.contained {
    background-color: #092948;
    margin: auto;
    padding-bottom: 60px;
}

.checkbox {
  background-color: rgba(255, 255, 255, 0.2); /* Transparent white background */
  border: 1px solid white; /* Solid white border */
  padding: 15px;
  border-radius: 4px;
  color: white;
  width:800px;
  padding-right: 40px;
}

.checkbox input[type="checkbox"] {
  opacity: 0; /* Hide the actual checkbox */
}


.checkbox input[type="checkbox"]:checked + ::before {
  background-color: white;
}

.selected {
    border: 2px solid #ffe089;
}

</style>