import { defineStore } from "pinia";

export const usePQOneStore = defineStore("PQOneStore", {
  state: () => ({
    Confident: 0,
    Workaholic: 0,
    MarketingGenius: 0,
    ResearchHero: 0,
    Extroverted: 0,
  }),
});
