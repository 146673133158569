<template>
    <div class="contained">
        <article class="message is-warning absolute w-full" v-if="displayAgreementWarning">
        <div class="message-header">
            <p> Please check <i>Agree to the T&C & processing of personal data*</i></p>
        </div>
        </article>

        <article class="message is-warning absolute w-full" v-if="displayInputWarning">
        <div class="message-header">
            <p> Please enter valid details to proceed.</p>
        </div>
        </article>

        <div class="content">
            <h1 class="is-size-1 has-text-white" style="padding-top: 10%">What kind of a <br> recruiter are you?</h1>
            <p class="is-size-5 has-text-white">Ever thought of what your true recruiter personality is? Take <br> this quiz and find out.</p>
        </div>

        <div>
             <p class="is-size-5 has-text-white has-text-weight-bold">Fill in the details to get started</p>
             <div style="width:25%; margin: auto" class="input-fields-div">
                <input class="input is-large mt-2 is-size-5" v-model="name" type="text" placeholder="Name*">
                <input class="input is-large mt-2 is-size-5" v-model="email" type="email" placeholder="Email*">
                <label class="checkbox mt-4">
                <input type="checkbox" v-model="agreement">
                <span class="has-text-white ml-2 is-size-7">I agree to the T&C & processing of personal data*</span>
                <button @click="startQuiz()" class="button is-large mt-8 rcrm-blue-bg has-text-white has-text-weight-semibold ">Let's go</button>
                </label>
             </div>
        </div>

        
    </div>
    
</template>

<script>
import { useGeneralStore } from '@/store/general';
import axios from 'axios'
    export default {
        name: 'PersonalityQuiz',
        data() {
            return {
                name: "",
                email: "",
                company: "None",
                agreement: false,
                displayAgreementWarning: false,
                displayInputWarning :false
            }
        },
        methods: {
               async addToMailchimp() {
                const formData = {
                email: this.email,
                name: this.name,
                company: this.company,
                tags: 'Personality Quiz 1'
                };
                axios
                .post('https://spartans.mediarcrm.com/api/subscribe', formData) // Replace with your actual API endpoint
                .then(response => {
                console.log(response.data.message);
                })
                .catch(error => {
                console.log('Subscription failed');
                });
            },
            isValidEmail(email) {
            // Regular expression to validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
            startQuiz() {
                useGeneralStore().name = this.name;
                useGeneralStore().email = this.email;
                if(this.agreement == true && this.name && this.isValidEmail(this.email)) {
                    this.addToMailchimp();
                this.$router.push('/pq-one');
                } 
                else if (this.agreement == false && this.name && this.isValidEmail(this.email)) {
                    this.displayAgreementWarning = true;
                    setTimeout(() => {
                        this.displayAgreementWarning = false;
                    }, 5000);
                }
                else if (this.agreement == false && !this.name && !this.isValidEmail(this.email)) {
                    this.displayAgreementWarning = true;
                    this.displayInputWarning = true;
                    setTimeout(() => {
                        this.displayAgreementWarning = false;
                        this.displayInputWarning = false;
                    }, 5000);
                }
                else if (this.agreement == true && !this.name && !this.isValidEmail(this.email)) {
                    this.displayAgreementWarning = true;
                    this.displayInputWarning = true;
                    setTimeout(() => {
                        this.displayAgreementWarning = false;
                        this.displayInputWarning = false;
                    }, 5000);
                }
            }
        }
    }

</script>

<style scoped>

.contained {
    background-color: #092948;
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .input-fields-div {
        width: 90% !important;
        margin: auto;
    }
}

</style>