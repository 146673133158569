<template>
     <div class="Section">
  <div class="pateny">
  <h1 class="title rcrm-blue has-text-weight-bold mt-10">Candidate sourcing 101</h1>
  </div>

  <div class="container">
    <p>With our comprehensive guide on candidate sourcing, learn to identify and attract top talent, creating a robust pipeline that ensures your hiring never misses a beat.</p>
    <!-- <p class="mt-5"> We have a bonus guide for you that includes ready-to-use email templates and tools you can use to source top-notch candidates. </p> -->
  </div>

  <div class="card-container is-flex is-flex-direction-row text-center">
    <div>
      <!-- card 1 -->
        <div class="card mr-4">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="https://recruitcrm.io/wp-content/uploads/2020/04/close-up-hands-with-different-photos-min-768x512.jpg" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                  <p style="font-weight:600; font-size:15px">All about candidate sourcing</p>
                  <a href="https://recruitcrm.io/wp-content/uploads/2023/08/All-about-candidate-sourcing-3.pdf" target="_blank">
                  <button class="button mt-0 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
    <div>
      <!-- card 2 -->
         <div class="card">
          <div class="card-image">
            <figure class="image is-4by3 relative">
              <img src="https://recruitcrm.io/wp-content/uploads/2023/04/pexels-ketut-subiyanto-4474047-1-768x512.jpg" alt="Placeholder image">
              <span class="absolute inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-bold text-green-700 top-2 left-2">Bonus Guide</span>
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                   <p style="font-weight:600; font-size:15px" class="">Email templates & tools</p>
                   <a href="https://recruitcrm.io/wp-content/uploads/2023/08/Candidate-sourcing-bonus-guide-3.pdf" target="_blank">
                  <button class="button mt-1 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
  </div>

  <div class="divider">
    <hr>
  </div>
  </div>
</template>

<style scoped>

h1 {
  font-weight: 900 !important;
}

p {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.container {
    width: 70%;
    margin-top: 25px;
}

.card-container {
    width: 28%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 481px) {
  
  .pateny {
    padding: 10px;
  }
  .card-container {
    width: 100%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}
}
</style>