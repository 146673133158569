<template>
    <div class="contained">

        <!-- The Confident One -->
        <div style="width: 550px; margin: auto;" v-if="maxVariable === 'Confident'" >
            <div class="content">
                <img src="../../assets/confident.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">The confident one</h1>
                <p class="is-size-5 has-text-white">You fear no one and are on your own. You trust your instincts and leave no room for self-doubt. Your confidence and faith in yourself are what boost you to hire the best candidates for your clients.</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the confident recruiter! 😎</p>
            </div>
        </div>
        <!-- The Confident One Ends -->

         <!-- The Workaholic One -->
        <div style="width: 550px; margin: auto;"  v-if="maxVariable === 'Workaholic'">
            <div class="content">
                <img src="../../assets/workaholic.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">The workaholic one</h1>
                <p class="is-size-5 has-text-white">You don’t mind working long hours. In fact, you love your work! No matter what you do, work is always on your mind. You truly live the quote- <i> Work is worship.</i>
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the workaholic recruiter! 😎</p>
            </div>
        </div>
        <!-- The Workaholic One Ends -->

        <!-- The Marketing Genius -->
        <div style="width: 550px; margin: auto;" v-if="maxVariable === 'MarketingGenius'">
            <div class="content">
                <img src="../../assets/genius.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">The marketing genius</h1>
                <p class="is-size-5 has-text-white">You know the art of selling. You have the best hacks up your sleeve to advertise your open jobs attractively, and your exceptional marketing skills allow you to find great candidates!
</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the marketing genius! 😎</p>
            </div>
        </div>
        <!-- The Marketung Genius Ends -->

          <!-- The Research Hero -->
        <div style="width: 550px; margin: auto;" class="" v-if="maxVariable === 'ResearchHero'">
            <div class="content">
                <img src="../../assets/researchHero.png" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0">The research hero</h1>
                <p class="is-size-5 has-text-white">You always look out for ways to increase your knowledge and have a learning plan for yourself. Be it through blogs, YouTube videos, or social media posts, you never miss a chance to learn more about the industry.

</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the research hero! 😎</p>
            </div>
        </div>
        <!-- The Research Hero Ends -->


          <!-- The  The Extroverted One
 -->
        <div style="width: 550px; margin: auto;" class="" v-if="maxVariable === 'Extroverted'">
            <div class="content">
                <img src="../../assets/extroverted.jpeg" style="height:300px; margin: auto; padding-top: 20px" alt="">
                <h1 class="is-size-1 has-text-white" style="padding-top: 0"> The extroverted one
</h1>
                <p class="is-size-5 has-text-white">You’re the talkative one and are probably the one to initiate conversations between candidates or clients. Networking is your superpower, and you can’t do without continuous interaction and engagement.


</p>
            </div>

            <div class="mb-2" style="width: 300px; margin: auto; border: 2px solid white;"> </div>

    

            <div style="width: 550px; margin: auto;" >
                <p class="text-white">Congrats! Share the results among your peers so that they know you are <br> the extroverted recruiter! 🤝</p>
            </div>
        </div>
        <!-- The The Extroverted One
 Ends -->


        
    </div>
    
</template>

<script>
import { useGeneralStore } from '../../store/general';
import { usePQOneStore } from '@/store/PQOne';
import axios from 'axios'
    export default {
        data() {
            return {
                name: useGeneralStore().name,
                email: useGeneralStore().email,
                agreement: false,
                displayAgreementWarning: false,
                displayInputWarning :false
            }
        },
        async mounted() {
           await this.submitForm();
        },
        methods: {
            async submitForm() {
            // Replace with your actual API endpoint URL
            const apiUrl = 'https://recruitcrm.io/wp-json/gf/v2/entries';
            const clientKey = 'ck_9b123faeb95c8ca71be9d600b870c6fdedf2b3e2';
            const clientSecret = 'cs_43aa1d28030bf8195b5ec2bf21756ea31f26ae46';

      // JSON data for the POST request
      const formData = {
        "form_id"      :"12",
        "date_created" :"2018-10-16 12:43:23",
        "is_starred"   :0,
        "is_read"      :1,
        "ip"           :"::1",
        "source_url"   :"https://recruitcrm.io/",
        "currency"     :"USD",
        "created_by"   :1,
        "user_agent"   :"Mozilla/5.0 (Windows NT 6.1; WOW64; rv:38.0) Gecko/20100101 Firefox/38.0",
        "status"       :"active",
        "1"          : this.name,
        "3"          : this.email,
        "4"            : "Personality Quiz 1",
        "5"            : this.maxVariable,

      };



      try {
        // Encode client key and secret
        const encodedCredentials = btoa(`${clientKey}:${clientSecret}`);

        // Axios POST request
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json',
          },
        });

        console.log('Response:', response.data);
        // Handle the response as needed
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        // Handle errors appropriately
      }
    },
        },
        computed: {
            maxVariable() {
            const store = usePQOneStore(); // Initialize the store
            const variables = Object.keys(store.$state);
            const values = variables.map(variable => store[variable]);

            const maxValue = Math.max(...values);
            const maxIndices = [];

            for (let i = 0; i < values.length; i++) {
                if (values[i] === maxValue) {
                maxIndices.push(i);
                }
            }

            if (maxIndices.length === 1) {
                return variables[maxIndices[0]];
            } else {
                const randomIndex = maxIndices[Math.floor(Math.random() * maxIndices.length)];
                return variables[randomIndex];
            }
            },
  },
}

</script>

<style scoped>

.contained {
    background-color: #092948;
    height: 100vh;
}

</style>