<template>
  <div class="">
  <div class="pateny">
  <h1 class="title mt-10 rcrm-blue has-text-weight-bold">Candidate experience 101</h1>
  </div>

  <div class="container">
    <p>With our comprehensive guide on candidate experience, master the strategies to make your recruitment process engaging and candidate-friendly, making your employer brand a top choice for talent.</p>
    <!-- <p class="mt-5"> We have a bonus guide for you that includes ready-to-use email templates and outreach messages you can use to provide a memorable experience to your candidates.</p> -->
  </div>

  <div class="card-container is-flex is-flex-direction-row text-center">
    <div>
      <!-- card 1 -->
        <div class="card mr-4">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="https://recruitcrm.io/wp-content/uploads/2022/01/collage-customer-experience-concept-768x512.jpg" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                  <p style="font-weight:600; font-size:15px">All about candidate experience</p>
                  <a href="https://recruitcrm.io/wp-content/uploads/2023/08/All-about-candidate-experience-1.pdf" target="_blank">
                  <button class="button mt-0 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
    <div>
      <!-- card 2 -->
         <div class="card">
          <div class="card-image">
            <figure class="image is-4by3 relative">
              <img src="https://recruitcrm.io/wp-content/uploads/2023/07/pexels-sora-shimazaki-5673491-min-scaled.jpg" alt="Placeholder image">
               <span class="absolute inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-bold text-green-700 top-2 left-2">Bonus Guide</span>
            </figure>
          </div>
          <div class="card-content">

            <div class="content">
              <div class="is-flex is-flex-direction-row">
                <div>
                   <p style="font-weight:600; font-size:15px" class="">Outreach templates & tools
</p>
                  <a href="https://recruitcrm.io/wp-content/uploads/2023/08/Candidate-experience-bonus-guide-1.pdf" target="_blank">
                  <button class="button mt-1 rcrm-green-button-nb">
                    Download now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Ends -->
    </div>
  </div>

  <div class="divider">
    <hr>
  </div>
  </div>
</template>

<style scoped>

h1 {
  font-weight: 900 !important;
}

p {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.container {
    width: 70%;
    margin-top: 25px;
}

.card-container {
    width: 28%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 481px) {
  
  .pateny {
    padding: 10px;
  }
  .card-container {
    width: 100%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}
}
</style>