<template>
  <div class="mx-auto flex">
    <div class="w-3/5 p-20 pt-10" style="background-color: #2376cd">

    <div class="text-white py-10 px-5 text-left" id="calendly-container" style="background-color: #2475cd; height: 100vh;" > 
        <div >
      <h2 class="text-2xl leading-10 font-bold mb-5 is-size-2 mb-2 mt-2" style="font-family: sans-serif;">Hey {{ name }}, <br> here are your results</h2>

      <p class="text-xl text-white mb-4 mt-20 is-size-3 font-medium leading-10">You’re saving <strong class="text-white font-medium">{{hourSaved}} hours ⌛ </strong>every month if you’re using an ATS
</p>
      <p class="text-xl text-white mb-4 mt-10 is-size-3 font-medium leading-10">Your ROI is $<strong class="text-white font-medium">{{ roiC }} 💰</strong> every month if you're investing in an ATS</p>
       <button type="submit" class="w-full py-4 bg-white text-white py-2 px-4 rounded is-size-4 mt-10" style="background-color: #10ac7b; font-weight: 600;" @click="newCalculation()">Start New Calculation</button>
      <!-- <h3 class="text-white is-size-4 mt-4 has-text-weight-bold" style="font-family:sans-serif">Book a demo to find out how much you can save</h3> -->
      
          <!-- <div class="calendly-inline-widget" data-url="https://calendly.com/recruitcrm/demo" style="min-width:320px;height:700px;" /> -->

    </div>
    </div>
    <!-- Calendly -->
         
    <!-- Calendly Ends -->
    </div>

    <!-- Second Half of the Screen -->
    <div class="w-2/5  bg-blue-200">
        <!-- <div class="calendly-inline-widget" data-url="https://calendly.com/recruitcrm/demo" style="min-width:320px;height:900px;" /> -->
    </div>

  </div>
</template>

<script>
import { useCalcStore } from '@/store/calc';
export default {
    data() {
      return {
        name: useCalcStore().name,
        hourSaved: useCalcStore().hours,
        roiC: useCalcStore().roi
      }
    },
    methods: {
      newCalculation() {
        this.$router.push('/roi-calculator')
      },
       loadCalendlyWidget() {
      // Create the Calendly badge widget script element
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;

      // Set the onload event to initialize the widget after the script is loaded
      script.onload = () => {
        Calendly.initBadgeWidget({
          url: 'https://calendly.com/recruitcrm/demo',
          text: 'I want a demo',
          color: '#0069ff',
          textColor: '#ffffff',
          branding: undefined
        });
      };

      // Append the script to the document's body
      document.body.appendChild(script);
    },
    },
    mounted() {
      this.loadCalendlyWidget()
    }
}
</script>

<style scoped> 

.headingg {
  font-size: 28px;
  line-height: normal;
  color: white;
}

.submitButton {
  background-color: #10ac7b;
  font-weight: 600;
  color: white;
  border: none;
  padding: 25px;
  width: 100%;
  font-size: 22px;
}

.submitButton:hover {
  background-color: #10ac7b;
  font-weight: 600;
  color: white;
  border: none;
  padding: 25px !important;
  width: 100%;
}


.container {
  font-family: 'Roboto', sans-serif;
  width: 100wh;
}

.container > div {
  box-sizing: border-box;
}

/* form input[type="text"],
form input[type="email"],
form input[type="number"] {
  background-color: rgba(255, 255, 255, 0.8);
  color: #115590;
} */

form label {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
</style>